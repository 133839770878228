import * as React from "react"

const DownloadsPage = () => {
  return (
    <main>
      <div><a href="https://releases.bonzai-editor.com/download/macos">MacOS (.zip)</a></div>
      <div><a href="https://releases.bonzai-editor.com/download/windows">Windows (.exe)</a></div>
      <div><a href="https://releases.bonzai-editor.com/download/debian">Debian (.deb)</a></div>
      <div><a href="https://releases.bonzai-editor.com/download/fedora">Fedora (.rpm)</a></div>
    </main>
  )
}

export default DownloadsPage
